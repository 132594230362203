import React from "react";
import { CommonTranslation } from "../components/CommonTranslations";
import Layout from "../components/layout";

export default function PrivacyPolicy({ location }: { location: Location }) {
  return (
    <Layout location={location} headerProps={{ filled: true }}>
      <div className="w-full h-screen text-white flex items-center justify-center">
        <h4 className="font-bold text-3xl">
          <CommonTranslation value="comingSoon" />
        </h4>
      </div>
    </Layout>
  );
}
